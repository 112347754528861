import React, { useContext, useMemo, useState } from "react";
import { applicationContext } from "../../context";
import ListButtonHandlers from "../ListButtonHandlers";
import "../DashboardList/dashboard.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DeleteButton } from "../DeleteButton";
import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { getDoc } from "firebase/firestore";
import { CheckIn } from "./CheckIn";

function DashboardList() {
  const { list, rides } = useContext(applicationContext);
  const [filteredList, setFilteredList] = useState([]);
  const [selectValue, setSelectValue] = useState("Select");
  const [selectDate, setSelectDate] = useState("Select");
  const handleDelete = async (resID, seats, tourID) => {
    
    const docRef = doc(db, "tours2024", tourID);
    const docRefTicket = doc(db, "tickets2024", resID);
    const tourSnap = await getDoc(docRef);
    
    const tourData = tourSnap.data();
    await updateDoc(docRef, {
      availableSeats: tourData.availableSeats + seats,
    });
    await updateDoc(docRefTicket, {
      isShadowDeleted: true
    });
    const newFilteredList = [...filteredList];
    newFilteredList.find(e => e.id===resID).data.isShadowDeleted = true;
    setFilteredList(newFilteredList)
  };
  const handleCheckIn = async(resID) => {
    
    const docRefTicket = doc(db, "tickets2024", resID);
    
    await updateDoc(docRefTicket, {
      isCheckedIn: true
    });
    const newFilteredList = [...filteredList];
    newFilteredList.find(e => e.id===resID).data.isCheckedIn = true;
    setFilteredList(newFilteredList)
  }
  const styleText = { color: "white", fontWeight: "700" };
  const options = useMemo(
    () =>
      [...new Set(filteredList.map((item) => item.data.date))].map((type) => {
        return { value: type };
      }),
    [filteredList]
  );
  return (
    <div
      style={{
        minHeight: "100vh",
        background: "black",
        color: "white",
        display: "flex",
        flexDirection: "column",
        width: "100vw"
      }}
    >
      <div className="select-div">
        <div className="choose-div">
          <label htmlFor="tour">
            Choose a Tour:
            <select
              defaultValue={1}
              name="tour"
              id=""
              onChange={(e) => {
                setFilteredList(
                  list.filter(
                    (el) =>
                       e.target.value.includes(el.data.boat) &&
                      Date.parse(el.data.date) + 86400000 > Date.now()
                  )
                );
                setSelectValue(e.target.value);
              }}
              value={selectValue}
            >
              <option value="select">Select</option>
              {rides.map(item => <option value={item.data.name+item.id}>{item.data.name}</option>)}
            </select>
          </label>
          <label htmlFor="dates">
            Choose a Date:
            <select
              name="date"
              id=""
              onChange={(e) => {
                setFilteredList((prev) =>
                  prev.filter((el) => el.data.date === e.target.value)
                );
                setSelectDate(e.target.value);
              }}
              value={selectDate}
            >
              <option value="select">Select</option>
              {options.map((el) => {
                // if (Date.parse(el.value) + 86400000 > Date.now()) {}
                return <option value={el.value}>{el.value}</option>;
              })}
            </select>
          </label>
        </div>
        <button
          className="select-button"
          onClick={() => {
            setFilteredList([]);
            setSelectValue("Select");
            setSelectDate("Select");
          }}
        >
          Clear
        </button>

      </div>
      {window.innerWidth < 700 ? (
        <div className="list-content">
          {filteredList.map((el) => {
            return (
              <>
                <ul style={{}}>
                  <li>
                    <span>Ime: </span> {el.data.roomNumber}
                  </li>
                  <li>
                    <span>Provajder: </span>{" "}
                    {el.data.provider || el.data.receptionist}
                  </li>
                  <li>
                    <span>Hotel: </span> {el.data.userData.hotel_name || "/"}
                  </li>
                  <li>
                    <span>Odrasli: </span> {el.data.numberOfPassengers}
                  </li>
                  <li>
                    <span>Deca: </span>
                    {el.data.preteens}
                  </li>
                  <li>
                    <span>Deca Gratis: </span>
                    {el.data.children}
                  </li>
                  <li>
                    <span>Paid/Not paid: </span>
                    {JSON.parse(el.data.isPaid) ? "paid" : "not paid"}
                  </li>
                  <li>
                    <span>Cena: </span>
                    {el.data.priceWithDiscount < el.data.ticketPriceDinars
                      ? el.data.priceWithDiscount
                      : el.data.ticketPriceDinars}
                    Dinara/{el.data.ticketPriceEuros}EUR
                  </li>
                  {el.data.paidWithDinars || el.data.paidWithEuros ? (
                    <p
                      style={{
                        background: "green",
                        padding: "1rem",
                        color: "white",
                        fontWeight: "500",
                      }}
                    >
                      Placeno {el.data.paidWithDinars ? "Dinarima" : ""}
                      {el.data.paidWithEuros ? "EUR" : ""}
                    </p>
                  ) : el.data.isShadowDeleted ?                       <p
                  style={{
                    background: "red",
                    padding: "1rem",
                    color: "white",
                    fontWeight: "500",
                  }}
                >
                  DELETED
                </p> : (
                    <div className="list-buttons">
                      <ListButtonHandlers
                        mod={"Placeno"}
                        ticketID={el.data.id}
                        filteredList={filteredList}
                        setFilteredList={setFilteredList}
                      />
                    </div>
                    
                  )}
                  {(el.data.isShadowDeleted || el.data.paidWithDinars || el.data.paidWithEuros  ) ? "" : <DeleteButton deleteHandler={() =>
                    handleDelete(
                      el.data.id,
                      el.data.numberOfPassengers + el.data.preteens + el.data.children,
                      el.data.tourID

                    )
                  }>DELETE</DeleteButton>}
                </ul>
              </>
            );
          })}
          <p>
        <p style={{ alignSelf: "center", marginTop: "2rem" }}>
          <div>Odrasli: {filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithDinars).reduce((sum, row) => sum + row.data.numberOfPassengers, 0) + filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithEuros).reduce((sum, row) => sum + row.data.numberOfPassengers, 0)}</div>
          <div>Deca: {filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithDinars).reduce((sum, row) => sum + row.data.preteens, 0) + filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithEuros).reduce((sum, row) => sum + row.data.preteens, 0)}</div>
          <div>Deca Gratis: {filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithDinars).reduce((sum, row) => sum + row.data.children, 0) + filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithEuros).reduce((sum, row) => sum + row.data.children, 0)}</div>
          <div>Ukupno: {filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithDinars).reduce((sum, row) => sum + row.data.numberOfPassengers + row.data.preteens + row.data.children, 0) + filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithEuros).reduce((sum, row) => sum + row.data.numberOfPassengers + row.data.preteens + row.data.children, 0)}
          </div>
          Total price : {filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithDinars).reduce((sum, item) => sum + item.data.priceWithDiscount, 0)} Dinara + {filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithEuros).reduce((sum, item) => sum + item.data.ticketPriceEuros, 0)}{" "}
          EUR{" "}
        </p>
          </p>
        </div>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: 1200,
            alignSelf: "center",
            backgroundColor: "#1E1E1E",
          }}
        >
          <Table sx={{ maxWidth: 1200 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={styleText}>
                  Check-in
                </TableCell>
                <TableCell align="center" sx={styleText}>
                  Ime
                </TableCell>
                <TableCell align="center" sx={styleText}>
                  Provajder
                </TableCell>
                <TableCell align="center" sx={styleText}>
                  Hotel
                </TableCell>
                <TableCell align="center" sx={styleText}>
                  Odrasli
                </TableCell>
                <TableCell align="center" sx={styleText}>
                  Deca
                </TableCell>
                <TableCell align="center" sx={styleText}>
                  Deca Gratis
                </TableCell>
                <TableCell align="center" sx={styleText}>
                  Cena
                </TableCell>
                <TableCell align="center" sx={styleText}>
                  Paid/Not Paid
                </TableCell>
                <TableCell align="center" sx={styleText}>
                  Nacin Placanja
                </TableCell>
                <TableCell align="center" sx={styleText}>
                  Obrisati
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredList.map((row,i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }}}
                >
                  {/* <TableCell component="th" scope="row">
                    {row.roomNumber}
                  </TableCell> */}
                  <TableCell align="center" sx={styleText}>
                    {console.log(row.data.isCheckedIn)}
                    <CheckIn checked={!!row.data.isCheckedIn} clickHandler={(e)=>{ if(!row.data.isCheckedIn){ handleCheckIn(row.data.id)}}} />
                    {/* <Checkbox checked={!!row.data.isCheckedIn} onChange={(e)=>{ if(!row.data.isCheckedIn){ handleCheckIn(row.data.id)}}} /> */}
                  </TableCell>
                  <TableCell align="center" sx={styleText}>
                    {row.data.roomNumber}
                  </TableCell>
                  <TableCell align="center" sx={styleText}>
                    {row.data.provider || row.data.receptionist}
                  </TableCell>
                  <TableCell align="center" sx={styleText}>
                    {row.data.userData.hotel_name || "/"}
                  </TableCell>
                  <TableCell align="center" sx={styleText}>
                    {row.data.numberOfPassengers}
                  </TableCell>
                  <TableCell align="center" sx={styleText}>
                    {row.data.preteens}
                  </TableCell>
                  <TableCell align="center" sx={styleText}>
                    {row.data.children}
                  </TableCell>
                  <TableCell align="center" sx={styleText}>
                    {row.data.priceWithDiscount < row.data.ticketPriceDinars
                      ? row.data.priceWithDiscount
                      : row.data.ticketPriceDinars}
                    Dinara / {row.data.ticketPriceEuros}EUR
                  </TableCell>
                  <TableCell align="center" sx={styleText}>
                   {JSON.parse(row.data.isPaid) ? "paid" : "not paid"}
                  </TableCell>
                  <TableCell align="center" sx={styleText}>
                    {row.data.paidWithDinars || row.data.paidWithEuros ? (
                      <p
                        style={{
                          background: "green",
                          padding: "1rem",
                          color: "white",
                          fontWeight: "500",
                        }}
                      >
                        Placeno {row.data.paidWithDinars ? "Dinarima" : ""}
                        {row.data.paidWithEuros ? "EUR" : ""}
                      </p>
                    ) : 
                      row.data.isShadowDeleted ?                       <p
                      style={{
                        background: "red",
                        padding: "1rem",
                        color: "white",
                        fontWeight: "500",
                      }}
                    >
                      DELETED
                    </p> : <div className="list-buttons">
                        <ListButtonHandlers
                          mod={"Placeno"}
                          ticketID={row.data.id}
                          filteredList={filteredList}
                          setFilteredList={setFilteredList}
                        />
                      </div>
                      
                    }
                  </TableCell>
                  <TableCell>
                  {(row.data.isShadowDeleted || row.data.paidWithDinars || row.data.paidWithEuros  ) ? "" : <DeleteButton deleteHandler={() =>
                    handleDelete(
                      row.data.id,
                      row.data.numberOfPassengers + row.data.preteens + row.data.children,
                      row.data.tourID

                    )
                  }>DELETE</DeleteButton>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {window.innerWidth > 700 ? (
        <p style={{ alignSelf: "center", marginTop: "2rem" }}>
          <div>Odrasli: {filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithDinars).reduce((sum, row) => sum + row.data.numberOfPassengers, 0) + filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithEuros).reduce((sum, row) => sum + row.data.numberOfPassengers, 0)}</div>
          <div>Deca: {filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithDinars).reduce((sum, row) => sum + row.data.preteens, 0) + filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithEuros).reduce((sum, row) => sum + row.data.preteens, 0)}</div>
          <div>Deca Gratis: {filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithDinars).reduce((sum, row) => sum + row.data.children, 0) + filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithEuros).reduce((sum, row) => sum + row.data.children, 0)}</div>
          <div>Ukupno: {filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithDinars).reduce((sum, row) => sum + row.data.numberOfPassengers + row.data.preteens + row.data.children, 0) + filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithEuros).reduce((sum, row) => sum + row.data.numberOfPassengers + row.data.preteens + row.data.children, 0)}
          </div>
          Total price : {filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithDinars).reduce((sum, item) => sum + item.data.priceWithDiscount, 0)} Dinara + {filteredList.filter(e => e.data.isShadowDeleted !== true).filter(e => e.data.paidWithEuros).reduce((sum, item) => sum + item.data.ticketPriceEuros, 0)}{" "}
          EUR{" "}
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

export default DashboardList;
